// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/KodeMono-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/KodeMono-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/KodeMono-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/KodeMono-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/KodeMono-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/KodeMono-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./assets/fonts/KodeMono-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./assets/fonts/KodeMono-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Kode Mono";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kode Mono";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kode Mono";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kode Mono";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
* {
  margin-block-start: 0;
  margin-block-end: 0;
  box-sizing: border-box;
  list-style-type: none;
  padding-inline-start: 0;
}

body {
  font-family: "Kode Mono", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/partials/_typography.scss","webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;EACA,kBAAA;ACAF;ADGA;EACE,wBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;EACA,kBAAA;ACFF;ADKA;EACE,wBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;EACA,kBAAA;ACJF;ADOA;EACE,wBAAA;EACA,oHAAA;EAEA,gBAAA;EACA,kBAAA;EACA,kBAAA;ACNF;AA1BA;EACE,qBAAA;EACA,mBAAA;EACA,sBAAA;EACA,qBAAA;EACA,uBAAA;AA4BF;;AAzBA;EACE,oCAAA;AA4BF","sourcesContent":["@font-face {\n  font-family: \"Kode Mono\";\n  src: url(\"../../assets/fonts/KodeMono-Regular.woff2\") format(\"woff2\"),\n    url(\"../../assets/fonts/KodeMono-Regular.woff\") format(\"woff\");\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Kode Mono\";\n  src: url(\"../../assets/fonts/KodeMono-Bold.woff2\") format(\"woff2\"),\n    url(\"../../assets/fonts/KodeMono-Bold.woff\") format(\"woff\");\n  font-weight: 700;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Kode Mono\";\n  src: url(\"../../assets/fonts/KodeMono-Medium.woff2\") format(\"woff2\"),\n    url(\"../../assets/fonts/KodeMono-Medium.woff\") format(\"woff\");\n  font-weight: 500;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"Kode Mono\";\n  src: url(\"../../assets/fonts/KodeMono-SemiBold.woff2\") format(\"woff2\"),\n    url(\"../../assets/fonts/KodeMono-SemiBold.woff\") format(\"woff\");\n  font-weight: 600;\n  font-style: normal;\n  font-display: swap;\n}\n","@use \"./components/partials/typography\" as *;\n* {\n  margin-block-start: 0;\n  margin-block-end: 0;\n  box-sizing: border-box;\n  list-style-type: none;\n  padding-inline-start: 0;\n}\n\nbody {\n  font-family: \"Kode Mono\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
