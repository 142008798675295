// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matrix {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  z-index: 0;
}
.matrix__effect {
  font: 800 1.5em/1 sans-serif;
  color: hsl(120, 100%, 50%);
  writing-mode: vertical-rl;
  white-space: nowrap;
  letter-spacing: 1ch;
  position: absolute;
  text-shadow: 5px 0 8px rgba(0, 255, 0, 0.8);
  animation: rain 20s linear infinite;
  box-shadow: 0 0 2rem 2rem rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
}
.matrix__effect::after {
  content: "";
  position: absolute;
  z-index: 2;
  inset: 0;
  background: linear-gradient(180deg, rgb(0, 0, 0), transparent);
}

@keyframes rain {
  0% {
    transform: translateY(-100%);
    rotate: y 0deg;
    opacity: 1;
  }
  25%, 75% {
    opacity: 0.8;
  }
  35%, 65% {
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) translateY(100vh);
    rotate: y 720deg;
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/matrix/Matrix.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,UAAA;AACF;AACE;EAEE,4BAAA;EACA,0BAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,2CAAA;EAEA,mCAAA;EAEA,4CAAA;EACA,mBAAA;AAHJ;AAMI;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,8DAAA;AAJN;;AAUA;EACE;IACE,4BAAA;IACA,cAAA;IACA,UAAA;EAPF;EAUA;IAEE,YAAA;EATF;EAWA;IAEE,UAAA;EAVF;EAYA;IACE,yCAAA;IACA,gBAAA;IACA,UAAA;EAVF;AACF","sourcesContent":[".matrix {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  background-color: black;\n  z-index: 0;\n\n  &__effect {\n    // added default structure, change layout to vertical\n    font: 800 1.5em/1 sans-serif;\n    color: hsl(120deg 100% 50%);\n    writing-mode: vertical-rl;\n    white-space: nowrap;\n    letter-spacing: 1ch;\n    //adding shadow tails to the text, and add pseudo-element for coverup, part1)\n    position: absolute;\n    text-shadow: 5px 0 8px rgba(0, 255, 0, 0.8);\n    //adding cycling animation for the text, part1)\n    animation: rain 20s linear infinite;\n\n    box-shadow: 0 0 2rem 2rem rgba(0, 0, 0, 0.4);\n    border-radius: 1rem;\n\n    //adding shadow tails to the text, and add pseudo-element for coverup, part2)\n    &::after {\n      content: \"\";\n      position: absolute;\n      z-index: 2;\n      inset: 0;\n      background: linear-gradient(180deg, rgba(0, 0, 0, 1), transparent);\n    }\n  }\n}\n\n//adding cycling animation for the text, part2)\n@keyframes rain {\n  0% {\n    transform: translateY(-100%);\n    rotate: y 0deg;\n    opacity: 1;\n  }\n\n  25%,\n  75% {\n    opacity: 0.8;\n  }\n  35%,\n  65% {\n    opacity: 1;\n  }\n  100% {\n    transform: rotate(0deg) translateY(100vh);\n    rotate: y 720deg;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
